import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'

import { nfts } from './deliJS/farmgod-core.js'
import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'


export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var popskullys = new web3.eth.Contract(
  nfts["popskullys"]["abi"], 
  nfts["popskullys"]["address"])

var ghostlys = new web3.eth.Contract(
  nfts["ghostlys"]["abi"], 
  nfts["ghostlys"]["address"])

var xcult = new web3.eth.Contract(
  nfts["popskullys"]["claim-abi"], 
  nfts["popskullys"]["claim-address"])

const letItRip = () => {
  getUserBalance()
}

const [userBal, setUserBal] = React.useState(0)
const [userBal0, setUserBal0] = React.useState(0)
const [userBal1, setUserBal1] = React.useState(0)

const getUserBalance = () => {
  popskullys.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setUserBal0(res)
    })
  ghostlys.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setUserBal1(res)
    })
}

React.useEffect(()=>{
  setUserBal(userBal0 + userBal1)
}, [userBal0, userBal1])


const [claimed, setClaimed] = React.useState(false)
const claim = () => {
  xcult.methods.claimAllForOwner()
    .send({from: window.ethereum.selectedAddress})
    .then((res)=>{
      setClaimed(true)
    }).catch((err)=>{
      alert("you already claimed $xCULT for at least one of your Pop Skullys or Ghostlys. Use the contract on ftmscan.com to claim for individual Skullys.")
      setClaimed(true)
    })
}

const connectOrClaim = () => {
  if (connected && !claimed && userBal > 0) {
    claim()
  } else if (!connected && !claimed) {
    connectMM()
  } else {

  }
}


  return (
    <div className={"App-wrapper"}>

   
      
      <div 
        className={
          "cult cult-connected--" + 
          connected + " cult-claimed--" + claimed + " cult-member--" + userBal}
        onClick={connectOrClaim}>
        <img src={nfts["popskullys"]["claim-icon"].default} />
      </div>
    </div>

  );
}

